<template>
  <div class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded">

    <POrderGrid
      ref="ordergrid"
      transactionType="TO"
      method="scheduleorders"
      nested="schedules"
      :cols="cols"
      :expandAll="expandAll"
      @click="onClick"
    ></POrderGrid>

    <portal to="actions">
      <div class="ml-4 space-x-4 flex items-center">

        <PToggle
          v-model="expandAll"
          :label="$tk('Common.General.Detailed')"
          :stacked="true"
        />

        <PButton
          @click="orderDialogVisible = true"
          :disabled="customer.statusId === 2"
          color="primary"
        >
          <span v-html="$tk('Scheduled.NewScheduledOrder')"></span>
        </PButton>

      </div>
    </portal>


    <OrderDialog
      v-if="orderDialogVisible"
      method="scheduleorder"
      transactionType="TO"
      :orderId="orderId"
      @close="onClose"
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OrderDialog from "../dialogs/OrderDialog"

export default {

  components: {
    OrderDialog
  },

  data() {
    return {
      cols: [
        { text: this.$tk('Common.Order.Active'), value: "isActive", component: "p-display-boolean", width: 50, xalign: "center" },
        { text: this.$tk('Common.Order.StartDate'), value: "dateBegins", sortable: true, component: "p-display-date", width: 110, xalign: "center" },
        { text: this.$tk('Common.Order.EndDate'), value: "dateEnds", sortable: true, component: "p-display-date", width: 110, xalign: "center" },
        { text: this.$tk('Common.Days.Mo')[0], value: "createMonday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Days.Tu')[0], value: "createTuesday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Days.We')[0], value: "createWednesday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Days.Th')[0], value: "createThursday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Days.Fr')[0], value: "createFriday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Order.Interval'), value: "createNumWeeks", component: "p-display-text", width: 80, align: "center" },
        {
          nested: [
            { text: this.$tk('Common.Order.RTI'), component: "p-display-product", aggregate: "p-display-product-images", nested: "schedules", align: "left", width: 250 },
            { text: this.$tk('Common.Order.Volume'), value: "quantityOrdered", component: "p-display-number", aggregate: "p-display-sum", nested: "schedules", width: 80, align: "right" },
          ]
        },
        { text: "%", value: "scheduleFill", component: "p-display-schedule-fill", align: "right", width: 100 },
        { text: this.$tk("Common.Order.DeliveryAddress"), value: "addressTo", sortable: true, component: "p-display-address", config: { showPrimary: false } },
        { text: this.$tk('Common.Order.MyReference'), value: "reference", component: "p-display-text", align: "left" },
      ],
      orderId: null,
      expandAll: false,
      filters: {},
      orderDialogVisible: false
    }
  },

  computed: {
    ...mapGetters(["customer"])
  },

  methods: {

    onClick({ order }) {
      this.orderId = order.id
      this.orderDialogVisible = true
    },

    onClose() {
      this.orderId = null
      this.orderDialogVisible = false
      this.$refs.ordergrid.getOrders()
    }
  }

}
</script>
